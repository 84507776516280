<!-- src/components/About.vue -->
<template>
  <div class="cover-container d-flex h-100 p-3 mx-auto flex-column">
    <header class="masthead mb-auto">
      <nav>
        <ul>
          <li><RouterLink to="/">Home</RouterLink></li>
        </ul>
      </nav>
      <nav>
        <p class="h4">Gurdwara Guru Har Rai - West Bromwich</p>
        <ul>
          <li>
            <RouterLink
              :to="{
                name: 'EntranceEnglish',
                query: { asthaan: 'guru-har-rai-sahib-west-bromwich' }
              }"
            >
              Entrance English
            </RouterLink>
          </li>
          <li>
            <RouterLink
              :to="{
                name: 'EntrancePunjabi',
                query: { asthaan: 'guru-har-rai-sahib-west-bromwich' }
              }"
              >Entrance Punjabi</RouterLink
            >
          </li>
        </ul>
      </nav>
      <nav>
        <h4>Singh Sabha - Somerset Road</h4>
        <ul>
          <li>
            <RouterLink
              :to="{
                name: 'EntranceEnglish',
                query: { asthaan: 'singh-sabha-somerset-road' }
              }"
              >Entrance English</RouterLink
            >
          </li>
          <li>
            <RouterLink
              :to="{
                name: 'EntrancePunjabi',
                query: { asthaan: 'singh-sabha-somerset-road' }
              }"
              >Entrance Punjabi</RouterLink
            >
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      // Add your data properties here
    };
  },
  methods: {
    // Add your methods here
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
