<!-- src/App.vue -->
<template>
  <main>
    <router-view></router-view>
  </main>
  <footer class="container-fluid">
    <small>{{ year }} Gurdwara Displays</small>
  </footer>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  beforeCreate: () => {
    document.documentElement.lang = "en-gb";
    document.body.className = "text-center";
    const mainContainer = document.getElementById("app");
    mainContainer.className = "container-fluid";
  }
};
</script>

<style>
/* You can add global styles here if needed */
</style>
